<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        :label="label"
        :data-vv-as="label"
        :name="name"
        v-model="aux_date"
        type="date"
        autocomplete="off" 
        :disabled="isViewModal"
        v-validate="getRequired"
        @change="changeDate"
      ></v-text-field>
      <span class="red--text">{{ errors.first(name) }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    date: { default: null, type: String },
    initToday: { default: false, type: Boolean },
    label: { default: "Date", type: String },
    name: { default: "date", type: String },
    isRequired: { default: false, type: Boolean },
    isViewModal: { default: false, type: Boolean },
    setDate: { type: Function },
  },

  data: (vm) => ({
    aux_date: null,
    menu1: false,
    fecha: null,
  }),
  computed: {
    getRequired() {
      return (this.isRequired)?'required':'';
    }
  },

  mounted() {    
    if(this.initToday){
      this.aux_date = new Date().toISOString().substr(0, 10);
      this.$emit("setDate", this.aux_date);
    }else{
      this.aux_date = this.date;
    }
  },
  watch: {
    date(val) {
      console.log("date", val);
      this.aux_date = val;
    },
  },
  methods: {  
    changeDate() {
      this.$emit("setDate", this.aux_date);
    },  
    required() {
      if (this.isRequired) return "required";
      return "";
    },
    
  },
};
</script>
