<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem" ref="modal_form">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="guia_madre" label="Guia Madre*" name="guia_madre" v-model="item.guia_madre" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("guia_madre") }}</span>
                <v-text-field data-vv-as="guia_hija" label="Guia Hija*" name="guia_hija" v-model="item.guia_hija" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("guia_hija") }}</span>

                <v-combobox
                  v-model="item.shipper"
                  data-vv-as="shipper"
                  name="shipper"
                  item-text="name"
                  item-value="id"
                  :items="shippers"
                  label="Shipper*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("shipper") }}</span>

                <v-combobox
                  v-model="item.consignee"
                  data-vv-as="consignee"
                  name="consignee"
                  item-text="name"
                  item-value="id"
                  :items="consignees"
                  label="Consignee*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("consignee") }}</span>

                <v-combobox
                  v-model="item.inssuing_carrier"
                  data-vv-as="inssuing_carrier"
                  name="inssuing_carrier"
                  item-text="name"
                  item-value="id"
                  :items="inssuing_carriers"
                  label="Inssuing Carrier*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("inssuing_carrier") }}</span>

                <v-combobox
                  v-model="item.departure_airport"
                  data-vv-as="departure_airport"
                  name="departure_airport"
                  item-text="name"
                  item-value="id"
                  :items="airports"
                  label="Departure Airport*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("departure_airport") }}</span>

                <v-combobox
                  v-model="item.transshipment_airport"
                  data-vv-as="transshipment_airport"
                  name="transshipment_airport"
                  item-text="name"
                  item-value="id"
                  :items="airports"
                  label="Transshipment Airport*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("transshipment_airport") }}</span>

                <v-combobox
                  v-model="item.carrier"
                  data-vv-as="carrier"
                  name="carrier"
                  item-text="name"
                  item-value="id"
                  :items="carriers"
                  label="By First Carrier*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("carrier") }}</span>
                <v-textarea
                  outlined
                  data-vv-as="accounting_information"
                  label="Accounting Information*"
                  name="accounting_information"
                  v-model="item.accounting_information"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-textarea>
                <span class="red--text">{{ errors.first("accounting_information") }}</span>

                <v-combobox
                  v-model="item.currency"
                  data-vv-as="currency"
                  name="currency"
                  item-text="name"
                  item-value="id"
                  :items="currencies"
                  label="Currency*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("currency") }}</span>

                <v-text-field data-vv-as="chgs_code" label="CHGS Code" name="chgs_code" v-model="item.chgs_code" :disabled="isViewModal()"></v-text-field>

                <div class="row">
                  <div class="col-md-12">
                    <label>WT/VAL</label>
                    <v-checkbox label="PPD" :disabled="isViewModal()" v-model="item.wtval_ppd"></v-checkbox>
                    <v-checkbox label="COLL" :disabled="isViewModal()" v-model="item.wtval_coll"></v-checkbox>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label>Other</label>
                    <v-checkbox label="PPD" :disabled="isViewModal()" v-model="item.other_ppd"></v-checkbox>
                    <v-checkbox label="COLL" :disabled="isViewModal()" v-model="item.other_coll"></v-checkbox>
                  </div>
                </div>

                <v-text-field
                  data-vv-as="declared_value_for_carriage"
                  label="Declared Value for carriage"
                  name="declared_value_for_carriage"
                  v-model="item.declared_value_for_carriage"
                  :disabled="isViewModal()"
                ></v-text-field>

                <v-text-field
                  data-vv-as="declared_value_for_customs"
                  label="Declared Value for customs"
                  name="declared_value_for_customs"
                  v-model="item.declared_value_for_customs"
                  :disabled="isViewModal()"
                ></v-text-field>

                <v-combobox
                  v-model="item.arrival_airport"
                  data-vv-as="arrival_airport"
                  name="arrival_airport"
                  item-text="name"
                  item-value="id"
                  :items="airports"
                  label="Destination Airport*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("arrival_airport") }}</span>
                <v-text-field
                  data-vv-as="requested_flight"
                  label="Requested Flight*"
                  name="requested_flight"
                  v-model="item.requested_flight"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("requested_flight") }}</span>
                <v-text-field data-vv-as="amount_insurance" label="Amount Insurance" name="amount_insurance" v-model="item.amount_insurance" :disabled="isViewModal()"></v-text-field>

                <v-textarea
                  outlined
                  data-vv-as="handling_information"
                  label="Handling Information*"
                  name="handling_information"
                  v-model="item.handling_information"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-textarea>
                <span class="red--text">{{ errors.first("handling_information") }}</span>

                <v-text-field data-vv-as="rcp" label="No of pieces RCP*" name="rcp" v-model="item.rcp" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("rcp") }}</span>
                <v-text-field data-vv-as="gross_weight" label="Gross Weight*" name="gross_weight" v-model="item.gross_weight" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("gross_weight") }}</span>
                <v-text-field data-vv-as="kilograms" label="Kg*" name="kilograms" v-model="item.kilograms" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("kilograms") }}</span>
                <v-text-field data-vv-as="rate_class" label="Rate class*" name="rate_class" v-model="item.rate_class" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("rate_class") }}</span>
                <v-text-field
                  data-vv-as="chargeable_weight"
                  label="Chargeable weight*"
                  name="chargeable_weight"
                  v-model="item.chargeable_weight"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("chargeable_weight") }}</span>
                <v-text-field data-vv-as="rate_charge" label="Rate charge*" name="rate_charge" v-model="item.rate_charge" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("rate_charge") }}</span>
                <v-text-field data-vv-as="total" label="Total*" name="total" v-model="item.total" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("total") }}</span>
                <v-textarea
                  outlined
                  data-vv-as="nature_quantity_goods"
                  label="Nature quantity of goods*"
                  name="nature_quantity_goods"
                  v-model="item.nature_quantity_goods"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-textarea>
                <span class="red--text">{{ errors.first("nature_quantity_goods") }}</span>

                <v-text-field data-vv-as="other_charges" label="Other charges*" name="other_charges" v-model="item.other_charges" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("other_charges") }}</span>
                <v-text-field
                  data-vv-as="total_other_charges"
                  label="Total other charges*"
                  name="total_other_charges"
                  v-model="item.total_other_charges"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("total_other_charges") }}</span>

                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :close-on-click="false"
                  :return-value.sync="item.executed_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="item.executed_date" label="Executed Date" readonly name="executed_date" v-bind="attrs" v-on="on" v-validate="'required'"></v-text-field>
                    <span class="red--text">{{ errors.first("executed_date") }}</span>
                  </template>
                  <v-date-picker v-model="item.executed_date" no-title scrollable min="1900-01-01">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(item.executed_date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="info" text @click="printModal(item)" v-if="!isViewModal() && this.item.id == 0">Imprimir</v-btn>
            <v-btn color="warning" text @click="nextModal(item)" v-if="!isViewModal() && this.item.id == 0">Siguiente</v-btn>
            <v-btn color="success" :loading="submit_loading" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { orderService } from "@/libs/ws/orderService";
import { generalService } from "@/libs/ws/generalService";
import ModalMixin from "@/mixins/ModalMixin.vue";
export default {
  mixins: [ModalMixin],
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    print: Function,
  },
  data() {
    return {
      menu: false,
      show: false,
      shippers: [],
      consignees: [],
      inssuing_carriers: [],
      airports: [],
      carriers: [],
      currencies: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadComboData() {
      generalService.filter({}, "currencies").then((response) => {
        this.currencies = response.result;
      });
      generalService.filter({}, "shippers").then((response) => {
        this.shippers = response.result;
      });
      generalService.filter({}, "consignees").then((response) => {
        this.consignees = response.result;
      });
      generalService.filter({}, "inssuing_carriers").then((response) => {
        this.inssuing_carriers = response.result;
      });
      generalService.filter({}, "airports").then((response) => {
        this.airports = response.result;
      });
      generalService.filter({}, "carriers").then((response) => {
        this.carriers = response.result;
      });
    },
    createItem(e, nextFlag = false) {
      if(this.loading)
        return;
      this.setLoading(true);  
      return this.$validator.validate().then((valid) => {
        if (valid) {
          if (e != null) e.preventDefault();
          if (!this.item.isExternal) {
            this.item.companyExternal = { name: "", id: 1 };
          }

          if (this.item.id > 0) {
            return this.service
              .update(this.item)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(()=> this.setLoading(false));
          } else {
            return orderService
              .create(this.item)
              .then((response) => {
                console.log(response);
                if (response.status && response.result.id > 0) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false, nextFlag);
                  this.$emit("loadList");
                  if (nextFlag) this.$validator.reset();
                  return response;
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                  return response;
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
                return response;
              })
              .finally(()=> this.setLoading(false));
          }
        }
      });
    },

    printModal() {
      this.createItem(null).then((response) => {
        this.$emit("print", response.result, "pdf");
      });
    },

    nextModal() {
      this.createItem(null, true);
    },
  },
};
</script>
