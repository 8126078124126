<template>
  <div>
    <Title :pages="[{ icon: 'chart-bar', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid v-if="list_access">
      <v-form>
        <div class="row filter justify-content-md-center mt-10">
          <div class="col-md-5">                   
            <div class="row mt-10" style="justify-content:center ; display: flex ; text-align: center">
              
              <div class="col-md-12" >
                <v-combobox 
                  label="Tipo de reporte*" name="tipo_reporte" :items="tipos_reportes" 
                  item-text="name"
                  item-value="id" v-model="tipo_reporte" v-validate="'required'"></v-combobox>
                <span class="red--text">{{ errors.first("tipodeinforme") }}</span>
              </div>

              <div class="col-md-12" v-if="tipo_reporte != '' && tipo_reporte.id != 'arribo_completo'">
                <v-combobox v-model="tipo_fecha" autocomplete="off" data-vv-as="tipos_fechas" name="tipos_fechas" :items="tipos_fechas" label="Fecha*" class="pt-0 mt-0" allow></v-combobox>
              </div>              

              <div class="col-md-12" v-if="tipo_reporte != ''">
                <v-text-field
                  label="Desde*"
                  data-vv-as="desde"
                  name="desde_selected"
                  v-model="desde_selected"
                  type="date"
                  v-validate="'required'"
                  autocomplete="off" 
                ></v-text-field>
                <span class="red--text">{{ errors.first("desde_selected") }}</span>
              </div>
            
            
              <div class="col-md-12" v-if="tipo_reporte != ''">
                <v-text-field
                  label="Hasta*"
                  data-vv-as="hasta"
                  name="hasta_selected"
                  v-model="hasta_selected"
                  type="date"
                  v-validate="'required'"
                  autocomplete="off" 
                ></v-text-field>
                <span class="red--text">{{ errors.first("hasta_selected") }}</span>                
              </div>

              <div class="col-md-12" v-if="tipo_reporte && tipo_reporte.id == 'carga_maritima'">
                <v-combobox                                  
                  v-model="consignee"
                  data-vv-as="consignee"
                  name="consignee"
                  item-text="name"
                  item-value="id"
                  v-validate="'required'"
                  :items="consignees"
                  label="Consignee*"
                  autocomplete="off"                  
                ></v-combobox>
                <span class="red--text">{{ errors.first("consignee") }}</span>
              </div>

              <div class="col-md-12" v-if="tipo_reporte && (tipo_reporte.id == 'aviso_arribo' || tipo_reporte.id == 'arribo_completo')">
                <v-combobox       
                  multiple         
                  v-model="consignee"
                  data-vv-as="consignee"
                  name="consignee"
                  item-text="name"
                  item-value="id"
                  v-validate="'required'"
                  :items="consignees"
                  label="Consignee*"
                  autocomplete="off"                  
                >
                  <v-list-tile
                      ripple
                      slot="prepend-item"
                      @click="selectAllConsignees"
                    >
                      <v-list-tile-title style="display:block;" class="ml-5 mt-3">
                        <v-btn small color="primary">Seleccionar Todos</v-btn>
                      </v-list-tile-title>
                    </v-list-tile>

                    <v-divider slot="prepend-item"></v-divider>
                </v-combobox>
                <span class="red--text">{{ errors.first("consignee") }}</span>
              </div>

              <div class="col-md-12" v-if="tipo_reporte && tipo_reporte.id == 'aviso_arribo'">
                <v-combobox
                  v-model="buque"
                  data-vv-as="buque"
                  name="buque"
                  item-text="name"
                  item-value="id"
                  v-validate="'required'"
                  :items="buques"
                  label="Buque*"
                  autocomplete="off"                  
                ></v-combobox>
                <span class="red--text">{{ errors.first("buque") }}</span>
              </div>

              <div class="col-md-12" v-if="tipo_reporte && tipo_reporte.id == 'arribo_completo'">
                <v-combobox
                  v-model="buque"
                  data-vv-as="buque"
                  name="buque"
                  item-text="name"
                  item-value="id"
                  v-validate="'required'"
                  :items="buques"
                  label="Buque*"
                  autocomplete="off" 
                  multiple
                >
                  <v-list-tile
                    ripple
                    slot="prepend-item"
                    @click="selectAllBuques"
                  >
                    <v-list-tile-title style="display:block;" class="ml-5 mt-3">
                      <v-btn small color="primary">Seleccionar Todos</v-btn>
                    </v-list-tile-title>
                  </v-list-tile>

                  <v-divider slot="prepend-item"></v-divider>
                </v-combobox>
              </div>

              <div class="col-md-12" v-if="tipo_reporte && tipo_reporte.id == 'arribo_completo'">
                <v-combobox
                  v-model="puerto"
                  data-vv-as="puerto"
                  name="puerto"
                  item-text="name"
                  item-value="id"
                  v-validate="'required'"
                  :items="puertos"
                  label="Puerto de Salida*"
                  autocomplete="off" 
                  multiple
                >
                  <v-list-tile
                    ripple
                    slot="prepend-item"
                    @click="selectAllPuertos"
                  >
                    <v-list-tile-title class="ml-5">
                      <v-btn>Seleccionar Todos</v-btn>
                    </v-list-tile-title>
                  </v-list-tile>

                  <v-divider slot="prepend-item"></v-divider>
                </v-combobox>
              </div> 

            </div>

            <div class="row mb-2" style="justify-content:center ; display: flex ; text-align: center">              
              <v-btn :loading="loading_reporte" @click="generateReport()" color="primary">
                Descargar
              </v-btn>
            </div>
          </div>
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { generalService } from "@/libs/ws/generalService";
import Constant from "@/constants/";
import Title from "@/components/Title.vue";
import { ordersGeneralService } from "@/libs/ws/ordersGeneralService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";

export default {
  components: { Title },
  mixins: [GenericMixin,RolMixin],
  data() {
    return {   
      page_title: "Reportes Ordenes Marítimas",      
      tipos_fechas: ["FECHA CREACION", "FECHA RECOLECCION", "FECHA RECOLECCION ( CONFIRMADA )", "FECHA SALIDA ( ETD )", "FECHA SALIDA ( ATD )", "FECHA LLEGADA ( ETA )", "FECHA LLEGADA ( ATA )"],
      tipos_reportes: [
        { id: "carga_maritima", name: "Carga Maritima"},
        { id: "aviso_arribo", name: "Aviso de Arribo"},
        { id: "arribo_completo", name: "Arribo Completo"},
        { id: "todos", name: "Todos los campos"}
      ],
      loading_reporte: false,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().slice(0, 10),
      service: ordersGeneralService,

      consignees: [],
      buques: [],
      puertos: [],

      tipo_reporte: "",
      tipo_fecha: "FECHA CREACION",
      consignee: null,
      buque: null,
      puerto: null,
    };
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    selectAllBuques() {
      this.buque = this.buques;
    },
    selectAllPuertos() {
      this.puerto = this.puertos;
    },
    selectAllConsignees() {
      this.consignee = this.consignees;
    },
    loadComboData() {
      generalService.filter({}, "consignees").then((response) => {
        this.consignees = response.result;
      });
      generalService.filter({}, "buques").then((response) => {
        this.buques = response.result;
      });
      generalService.filter({}, "puertos").then((response) => {
        this.puertos = response.result;
      });
    },
    generateReport(){
      if(this.tipo_reporte.id == undefined){
        this.$toast.error("Debe seleccionar un tipo de reporte", "Error");
        return;
      }

      this.$validator.validate().then((valid) => {
        if (!valid ) {
          this.$toast.error("Debe completar los campos requeridos", "Error");
          return;
        }

        this.loading_reporte = true;
        this.service.setSection(Constant.SECTION_ORDERS_MARITIMAS_COM);
        
        this.service.export_filter(this.getFilterParams()).then((response) => {
          try {
            //Transformo a string la respuesta para poder enviar mensajes de error.
            let result = JSON.parse(this.arrayBufferToString(response));
            if (!result.status) {
              this.$toast.error(result.msg, "Error");
            }
          } catch (e) {
            let blob = new Blob([response], {
              type: "application/vnd.ms-excel",
            });
            let link = document.createElement("a");
            //link.setAttribute('download', "export")
            link.download = this.getExportName()+".xlsx";
            link.href = window.URL.createObjectURL(blob);
            link.target = "_blank";
            //link.download = code+'.pdf'
            link.click();
          }
        })
        .catch((error) => {
          this.$toast.error("Error al descargar el reporte", "Error");
        })
        .finally(() => {
          this.loading_reporte = false;
        });
      });
    },
    getExportName(){
      //Get now Y-m-d string
      let date = new Date();           

      switch(this.tipo_reporte.id){
        case "carga_maritima":
          return this.consignee.name+"_Carga Maritima_"+this.desde_selected+"_"+this.hasta_selected;
        case "aviso_arribo":
          return this.buque.name+"_Aviso de Arribo_"+this.desde_selected+"_"+this.hasta_selected;
        case "arribo_completo":
          return "Arribo Completo_"+this.desde_selected+"_"+this.hasta_selected;
        default:
          return "Reporte_"+this.desde_selected+"_"+this.hasta_selected;

      }
    },
    getFilterParams() {
      let param = {
        desde: this.desde_selected,
        hasta: this.hasta_selected,         
        tipo_reporte: this.tipo_reporte.id,
        tipo_fecha: this.tipo_fecha,
      };

      if(this.tipo_reporte.id == "carga_maritima"){
        param.consignee = [this.consignee];
      }
      if(this.tipo_reporte.id == "aviso_arribo"){
        param.consignee = this.consignee;
        param.buque = this.buque.id;
      }
      if(this.tipo_reporte.id == "arribo_completo"){
        param.buque = this.buque;
        param.puerto = this.puerto;
        param.tipo_fecha = this.tipos_fechas[5];//ETA
      }

      return param;
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
