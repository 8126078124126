<template>
  <v-card @mouseenter="handleExpandMenu(true)" @mouseleave="handleExpandMenu(false)" height="100vh" width="256"
    class="mx-auto">
    <v-navigation-drawer v-model="$store.state.sidebar.drawer" :width="240" dark app
      :expand-on-hover="!$store.state.sidebar.open" class="rounded-0">
      <div>
        <v-list style="height:100px">
          <v-list-item>
            <v-list-item-content class="pt-1">
              <v-img v-show="expandMenu" transition="fade-transition" :src="require('@/assets/images/logo.png')" contain
                max-width="125" class="pt-0" />

              <v-img v-show="!expandMenu" transition="fade-transition" :src="require('@/assets/images/logo-mini.png')"
                contain height="60" class="pt-8" />
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider class="mt-0"></v-divider>
      </div>
      
      <div style="scroll-margin-top: 100px" v-for="item in items" :key="item.title">
        <!-- <span v-if="$store.state.sidebar.open"></span> -->
        <p class="title-section mb-0" v-if="item.visible && expandMenu"> {{ item.section }}</p>
        <p class="title-section mb-0" v-else-if="item.visible && !expandMenu">{{ item.section_collapse }}</p>
        <div v-for="child in item.children" :key="child.title">

          <v-list v-if="child.visible" dense nav>
            <v-list-group v-if="child.visible && 'items' in child && child.items.length > 0" v-model="child.active"
              :key="child.title" no-action>
              <v-list-item slot="activator">
                <v-list-item-icon>
                  <font-awesome-icon :icon="child.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title >
                   {{ child.title }}                                             
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
                            
              <router-link v-for="subItem in child.items" :key="subItem.title" :to="'/' + subItem.path">
                <v-list-item v-if="subItem.visible" ripple @click="close" class="subitem">
                  
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ subItem.title }}                                          
                    </v-list-item-title>
                    
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </v-list-group>

            <router-link v-else-if="child.visible" :to="'/' + child.path">
              <v-list-item :key="child.title" link>
                <v-list-item-icon>
                  <font-awesome-icon :icon="child.icon" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title :style="(child.title == 'Notificaciones')?'padding: 5px':''">
                    {{ child.title }} 
                    <span
                      v-if="child.title == 'Notificaciones' && $store.state.alerts > 0"
                      class="alertas_notificacion"
                    >
                    {{$store.state.alerts}}
                    </span>  
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import ConstantSection from "@/constants/";
import { cryptoUtils } from "@/libs/utils/cryptoUtils";
import { generalService } from "@/libs/ws/generalService";
import RolMixin from "@/mixins/RolMixin.vue";
export default {
  mixins: [RolMixin],
  RolMixin,
  data() {
    return {
      isExternal: null,
      alertTimer: null,
      items: [
        {
          section: "Aéreas",
          section_collapse: "A...",
          visible: false,
          children: [
            {
              title: this.$t("Ordenes"),
              icon: "file-invoice",
              active: false,
              path: ConstantSection.SECTION_ORDERS,
              visible: false,
            },
          ]
        },
        {
          section: "Maritimas",
          section_collapse: "M...",
          visible: false,
          children: [
            {
              title: this.$t("Ordenes Comerciales"),
              icon: "file-invoice",
              active: false,
              path: "orders_maritimas_comercial",
              visible: false,
            },
            {
              title: this.$t("Ordenes Operativas"),
              icon: "file-invoice",
              active: false,
              path: "orders_maritimas_operaciones",
              visible: false,
            },
            {
              title: this.$t("Reportes"),
              icon: "file-invoice",
              active: false,
              path: "orders_maritimas_reports",
              visible: false,
            },
          ]
        },
        {
          section: "Terrestres",
          section_collapse: "T...",
          visible: false,
          children: [
            {
              title: this.$t("Ordenes Comerciales"),
              icon: "file-invoice",
              active: false,
              path: "orders_terrestres_comercial",
              visible: false,

            },
            {
              title: this.$t("Ordenes Operativas"),
              icon: "file-invoice",
              active: false,
              path: "orders_terrestres_operaciones",
              visible: false,
            },
            /*
            {
              title: this.$t("Reportes"),
              icon: "file-invoice",
              active: false,
              path: "orders_terrestres_reports",
              visible: false,
            },
            */
          ],
        },
        {
          section: "Maritime Invoices",
          section_collapse: "FM...",
          visible: false,
          children: [
            {
              title: this.$t("Maritime Invoices"),
              icon: "file-invoice",
              active: false,
              path: ConstantSection.SECTION_MARITIME_AGENT_INVOICES,
              visible: false,
            },
          ]
        },
        {
          section: "Notificaciones",
          section_collapse: "N...",
          visible: false,
          children: [
            {
              title: this.$t("Notificaciones"),
              icon: "envelope",
              active: false,
              path: ConstantSection.SECTION_NOTIFICATIONS,
              visible: false,
            },
          ]
        },
        {
          section: "General",
          section_collapse: "G...",
          visible: false,
          children: [
            {
              title: this.$t("Administrar"),
              icon: "cog",
              active: false,
              visible: false,
              items: [
                { title: this.$t("Currencies"), path: ConstantSection.SECTION_CURRENCY, visible: false },
                { title: this.$t("Shipper"), path: ConstantSection.SECTION_SHIPPER, visible: false },
                { title: this.$t("Consignee"), path: ConstantSection.SECTION_CONSIGNEE, visible: false },
                { title: this.$t("Inssuing carrier"), path: ConstantSection.SECTION_INSSURING_CARRIER, visible: false },
                { title: this.$t("Airport"), path: ConstantSection.SECTION_AIRPORT, visible: false },
                { title: this.$t("Carrier"), path: ConstantSection.SECTION_CARRIER, visible: false },

                { title: this.$t("Tipo de Contenedor"), path: ConstantSection.SECTION_TIPO_CONTENEDOR, visible: false },
                { title: this.$t("Puerto"), path: ConstantSection.SECTION_PUERTO, visible: false },
                { title: this.$t("Buque"), path: ConstantSection.SECTION_BUQUE, visible: false },
                { title: this.$t("Compania de Trasnporte"), path: ConstantSection.SECTION_COMPANIA_TRASNPORTE, visible: false },
                { title: this.$t("Tipo de Camion"), path: ConstantSection.SECTION_TIPO_CAMION, visible: false },
                { title: this.$t("Lugar de Salida-Llegada"), path: ConstantSection.SECTION_LUGAR, visible: false },
                { title: this.$t("Estados"), path: ConstantSection.SECTION_ESTADO, visible: false },
                { title: this.$t("Codigos de Puertos"), path: ConstantSection.SECTION_CODIGO_PUERTO, visible: false },
                { title: this.$t("Navieras"), path: ConstantSection.SECTION_NAVIERA, visible: false },
                { title: this.$t("Terminales Dep. Fiscales"), path: ConstantSection.SECTION_TERMINAL_DEP_FISCAP, visible: false },                

                { title: this.$t("Roles"), path: ConstantSection.SECTION_ROLE, visible: false },
                { title: this.$t("Agentes"), path: ConstantSection.SECTION_AGENTE, visible: false },
                { title: this.$t("Usuarios"), path: ConstantSection.SECTION_USER, visible: false },
              ],
            }
          ],

        },
      ],
      right: null,
      expandMenu: this.$store.state.sidebar.open ? true : false,
      isMobile: false,
      show: true,
    };
  },
  mounted() {
    this.loadSideBarExternal();    
    this.loadNotifications();
    this.alertTimer = setInterval(() => {
      this.loadNotifications();
    }, 60 * 1000);     
  },
  beforeDestroy() {
    clearInterval(this.alertTimer);
  },
  watch: {
    "$store.state.sidebar.open": function () {     
      if (this.$store.state.sidebar.open) {
        this.expandMenu = true;
      } else {
        this.expandMenu = false;
      }
    },
  },
  methods: {
    loadNotifications() {      
      //solo si el usuario tiene permiso de lectura
      if(this.roles[ConstantSection.SECTION_NOTIFICATION] != undefined && this.roles[ConstantSection.SECTION_NOTIFICATION].list_access){
        generalService.setSection(ConstantSection.SECTION_NOTIFICATIONS);
        generalService.filter({ readed: 0 }).then((response) => {        
          //this.alerts = response.result.length;
          console.log(response);
          this.$store.commit("setAlerts", {
              alerts: response.result.length
          });
        });
      }
    },
    handleExpandMenu(value) {
      if (this.$store.state.sidebar.open) {
        this.expandMenu = true;
      } else {
        this.expandMenu = value;
      }
    },
    close() { },
    loadSideBarExternal() {
      this.items = this.getItemsAvailable();
      /*
      this.isExternal = cryptoUtils.decrypt(localStorage.getItem("external"), ConstantSection.SECRET_PHRASE);
      if (this.isExternal != null && this.isExternal == false) {
        this.items = this.itemsAdmin;
      } else {
        this.items = this.itemsCliente;
      }
      */
    },
    getItemsAvailable() {
      let itemsAvailable = this.items;
      itemsAvailable.forEach((item) => {
        if ("children" in item) {
          item.visible = true;
          let any_visible = false;
          item.children.forEach((child) => {
            if ("items" in child) {
              child.visible = false
              child.items.forEach((sub_item) => {
                sub_item.visible = sub_item.path in this.roles && this.roles[sub_item.path].sidebar;
                if (sub_item.visible){
                  child.visible = true;
                  any_visible = true;
                }
              });
            } else {

              child.visible = child.path in this.roles && this.roles[child.path].sidebar;
              if (child.visible) any_visible = true;
            }
          });

          item.visible = any_visible;
        } else {
          item.visible = item.path in this.roles && this.roles[item.path].sidebar;
        }
      });

      return itemsAvailable;
    },
  },
};
</script>
