<template>
  <div>
    <v-row class="mt-2">
      <v-col>
        <v-btn color="info" class="mb-3" small @click="addItem()" v-if="!isViewModal">+ Agregar</v-btn>
      </v-col>
    </v-row>

    <v-data-table class="pb-4 mb-5" :headers="headers" :items="items" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
      <template v-slot:item="props">
        <tr style="height: 100px">
          <td v-for="input_field in input_fields" :key="input_field.name + '_' + items.indexOf(props.item)" :style="getInputWidth(input_field)">
            <v-text-field
              dense
              v-if="input_field.type == 'text'"
              :data-vv-as="input_field.name"
              :label="input_field.label"
              :name="input_field.name + '_' + items.indexOf(props.item)"
              v-model="props.item[input_field.name]"
              :disabled="isViewModal"
              v-validate="getVValidate(input_field)"
            />

            <v-text-field
              dense
              v-if="input_field.type == 'numeric'"
              :data-vv-as="input_field.name"
              :label="input_field.label"
              :name="input_field.name + '_' + items.indexOf(props.item)"
              v-model="props.item[input_field.name]"
              v-validate="getVValidate(input_field)"
              :disabled="isViewModal"
              @change="test()"
            />

            <v-combobox
              autocomplete="off"
              v-if="input_field.type == 'select'"
              :data-vv-as="input_field.name"
              :label="input_field.label"
              :name="input_field.name + '_' + items.indexOf(props.item)"
              v-model="props.item[input_field.name]"
              :item-text="input_field.list_name"
              :item-value="input_field.list_id"
              :items="input_field.list"
              v-validate="getVValidate(input_field)"
              :disabled="isViewModal"
            />

            <v-file-input
              dense
              truncate-length="15"
              v-if="input_field.type == 'file'"
              :accept="input_field.allowed_mime_types"
              :data-vv-as="input_field.name"
              :label="props.item[input_field.name_attached] != undefined ? props.item[input_field.name_attached] : input_field.label"
              :name="input_field.name + '_' + items.indexOf(props.item)"
              v-model="props.item[input_field.name]"
              :disabled="isViewModal || props.item[input_field.name_attached] != undefined"
              v-validate="getVValidate(input_field)"
            />

            <span class="red--text">{{ errors.first(input_field.name + "_" + items.indexOf(props.item)) }}</span>
          </td>

          <td class="text-center" style="width:120px">
            <v-tooltip bottom v-if="isViewModal && props.item.url != null">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-5" medium color="blue" @click="download(props.item.url, props.item.id)"> mdi-download </v-icon>
              </template>
              <span>Descargar</span>
            </v-tooltip>

            <v-tooltip bottom v-if="!isViewModal">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" medium color="red" @click="removeItem(props.item)"> indeterminate_check_box </v-icon>
              </template>
              <span>Remover</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { filesService } from "@/libs/ws/filesService";

export default {
  mixins: [],
  components: {},
  props: {
    isViewModal: Boolean,
    table_title: String,
    headers: Array,
    items: Array,
    input_fields: Array,
    item_template: Object,
    validator: Object,
  },
  data() {
    return {};
  },
  created() {
    this.$validator = this.validator;
  },
  watch: {
    items: {
      handler(val) {},
      deep: true,
    },
  },
  mounted() {},
  methods: {
    addItem() {
      this.items.push(JSON.parse(JSON.stringify(this.item_template)));
    },
    removeItem(item) {
      const index = this.items.indexOf(item);
      if (index > -1) {
        this.items.splice(index, 1);
      }
    },
    getInputWidth(input_field) {
      if (input_field.width != undefined) return "width: " + input_field.width;
      return "";
    },
    getVValidate(input_field) {
      let validate = "";
      if (input_field.required) validate = validate + "required|";

      if (input_field.type == "numeric") {
        validate += validate + input_field.validate + "|";
        console.log("validate", validate);
      }
      return validate;
    },
    test() {
      console.log(this.errors);
    },
    download(url, name) {
      this.loading = true;
      filesService
        .downloadOVHFile(url)
        .then((response) => {
          console.log(response.data);

          let blob = new Blob([response.data], {
            type: "application/pdf", //TODO: change for attachment
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = name;
          link.click();
        })
        .catch((response) => {
          this.$toast.error(response.msg, "Error");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
