<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: 'TERRESTRES | Ordenes Comerciales' }]"></Title>

    <v-container fluid>
      <ModalComercial
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @print="print"
        @loadList="loadList(service, getFilterParams());"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0 ">
                  <v-text-field
                    label="Desde*"
                    data-vv-as="desde"
                    name="desde_selected"
                    v-model="desde_selected"
                    type="date"                    
                    autocomplete="off" 
                  ></v-text-field>
                  <span class="red--text">{{ errors.first("desde_selected") }}</span>                      
                </div>

                <div class="col-md-3 pt-0 ">
                  <v-text-field
                    label="Hasta*"
                    data-vv-as="hasta"
                    name="hasta_selected"
                    v-model="hasta_selected"
                    type="date"                    
                    autocomplete="off" 
                  ></v-text-field>
                  <span class="red--text">{{ errors.first("hasta_selected") }}</span>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox v-model="tipo_fecha_selected" autocomplete="off" data-vv-as="tipos_fechas" name="tipos_fechas" :items="tipos_fechas" label="Fecha*" class="pt-0 mt-0" allow></v-combobox>
                </div>               
                
                <div class="col-md-3 pt-0">
                  <v-text-field v-model="factura_selected" autocomplete="off" data-vv-as="factura" name="factura" label="Factura" class="pt-0 mt-0"></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field
                    v-model="referencia_cliente_selected"
                    autocomplete="off"
                    data-vv-as="referencia_cliente"
                    name="referencia_cliente"
                    label="Referencia Cliente"
                    class="pt-0 mt-0"
                  ></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field v-model="factura_agente_selected" autocomplete="off" data-vv-as="factura_agente" name="factura_agente" label="Factura Agente" class="pt-0 mt-0"></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field v-model="proformas_numero_selected" autocomplete="off" data-vv-as="proformas_numero" name="proformas_numero" label="Proformas Nº" class="pt-0 mt-0"></v-text-field>
                </div>

                <div class="col-md-12 pt-0" style="display: flex;justify-content: end;">
                  <!--<v-btn color="error" class="mr-2" @click="export_filter">Exportar</v-btn>-->
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando..." hide-default-footer disable-pagination>
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.operacion }}</td>
              <td>{{ showName(props.item.consignee) }}</td>
              <td>{{ props.item.crt }}</td>
              <td>{{ props.item.fecha_salida_etd }}</td>
              <td>{{ props.item.fecha_llegada_eta }}</td>
              <td>{{ showName(props.item.lugar_salida) }}</td>              
              <td>{{ showName(props.item.lugar_llegada) }}</td>

              <td class="justify-content-center px-0">
                <!--<v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="#6e6e6e" class="mr-2" @click="print(props.item, 'print')">
                      print
                    </v-icon>
                  </template>
                  <span>Imprimir</span>
                </v-tooltip>-->

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="orange" class="mr-2" @click="openHistoryEstados(props.item)">timer</v-icon>
                  </template>
                  <span>Ver Historial Estados</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="grey" class="mr-2" @click="copy(props.item)">
                      content_copy
                    </v-icon>
                  </template>
                  <span>Copiar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)">
                      edit
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)">
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)">
                      delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
      <DialogLoading :dialog="dialog_print" text="Imprimiendo..." />

      <HistoryModal ref="historyStatesModal" />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/";
import { generalService } from "@/libs/ws/generalService";
import { ordersGeneralService } from "@/libs/ws/ordersGeneralService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import DialogLoading from "@/components/DialogLoading.vue";
import ModalComercial from "./ModalComercial.vue";
import HistoryModal from "./HistoryModal.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import axios from "axios";

export default {
  components: { Title, HistoryModal, ModalComercial, DialogDelete, DialogLoading },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      parent: this,
      service: ordersGeneralService,
      page_title: "Ordenes Terrestres",
      modal_title: "Orden Terrestre",
      mainItem: {
        id: "",
        operacion: null,
        shipper: null,
        consignee: null,
        facturas: [],        
        packing_lists: [],
        referencia_cliente: null,
        cantidad_camiones: null,
        camiones:[],
        crt: null,
        mic: null,  
        fecha_recoleccion: null,
        fecha_recoleccion_confirmada: null,
        fecha_salida_etd: null,
        fecha_salida_atd: null,
        fecha_llegada_eta: null,
        fecha_llegada_ata: null,
        ultimo_estado: null,      
        comentarios: null,
        lugar_salida: null,
        lugar_llegada: null,
        //ultimo_camion: null,
        facturas_agentes: [],        
      },
      defaultItem: null,
      headers: [
        { text: "Nº Operacion", align: "left", sortable: true, value: "operacion" },
        { text: "Consignee", align: "left", sortable: true, value: "consignee.name" },
        { text: "CRT", align: "left", sortable: true, value: "crt" },
        { text: "Fecha Salida ETD", align: "left", sortable: true, value: "fecha_salida_etd" },
        { text: "Fecha Llegada ETA", align: "left", sortable: true, value: "fecha_llegada_eta" },
        { text: "Lugar de salida", align: "left", sortable: true, value: "lugar_salida.name" },        
        { text: "Lugar de llegada", align: "left", sortable: true, value: "lugar_llegada.name" },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      //Dialogs
      dialog_delete: false,
      dialog_print: false,
      //Combos
      tipos_camiones: [],
      shippers: [],
      consignees: [],
      //Filters
      tipos_fechas: ["FECHA CREACION", "FECHA RECOLECCION", "FECHA RECOLECCION ( CONFIRMADA )", "FECHA SALIDA ( ETD )", "FECHA SALIDA ( ATD )", "FECHA LLEGADA ( ETA )", "FECHA LLEGADA ( ATA )"],
      tipo_fecha_selected: "FECHA CREACION",
      factura_selected: null,
      factura_agente_selected: null,
      referencia_cliente_selected: null,
      proformas_numero_selected: null,
      desde_selected_open: false,
      hasta_selected_open: false,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),      
    };
  },
  mounted() {
    this.service.setSection(Constant.SECTION_ORDERS_TERRESTRES_COM);
    this.loadComboData();
    this.defaultItem = Object.assign({}, this.mainItem);
    this.loadList(this.service, this.getFilterParams());
    this.tipo_fecha_selected = this.tipos_fechas[0];
  },
  methods: {
    showName(element) {
      return element != null ? element.name : "-";
    },
    openHistoryEstados(order) {
      this.$refs.historyStatesModal.open(
        "Estados",
        [
          { text: "Fecha", align: "left", sortable: true, value: "fecha_modificacion" },
          { text: "Estado", value: "estado.name" },
        ],
        order.historial_estados,
        "fecha_modificacion",
        "estado"
      );
    },
    openHistoryBuques(order) {
      this.$refs.historyStatesModal.open(
        "Buques",
        [
          { text: "Fecha", align: "left", sortable: true, value: "fecha_modificacion" },
          { text: "Buque", value: "buque.name" },
        ],
        order.historial_buques,
        "fecha_modificacion",
        "buque"
      );
    },
    getFilterParams() {
      const param = {
        desde: this.desde_selected,
        hasta: this.hasta_selected,
        tipo_fecha: this.tipo_fecha_selected,

        proformas_numero: this.proformas_numero_selected,
        factura: this.factura_selected,
        referencia_cliente: this.referencia_cliente_selected,
        factura_agente: this.factura_agente_selected,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadComboData() {
      generalService.filter({}, "shippers").then((response) => {
        this.shippers = response.result;
      });
      generalService.filter({}, "consignees").then((response) => {
        this.consignees = response.result;
      });
      generalService.filter({}, "tipos_camiones").then((response) => {
        this.tipos_camiones = response.result;
      });
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service, this.getFilterParams());
    },
    updateDialog(value, next_flag = false) {
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
      if (!value) {
        this.closeModal(next_flag);
      } else {
        this.dialog = value;
      }
    },
    closeModal(next_flag) {
      if (!next_flag) {
        this.dialog = false;
        this.close();
        return;
      }

      const mainItem_aux = this.mainItem;

      this.mainItem = Object.assign({}, this.defaultItem);

      this.mainItem.shipper = mainItem_aux.shipper;
      this.mainItem.consignee = mainItem_aux.consignee;

      this.dialog = false;
      this.editedIndex = -1;
      this.viewdIndex = -1;
      this.errors.clear();
    },
    export_filter() {
      this.service.export_filter(this.getFilterParams()).then((response) => {
        try {
          //Transformo a string la respuesta para poder enviar mensajes de error.
          let result = JSON.parse(this.arrayBufferToString(response));
          if (!result.status) {
            this.$toast.error(result.msg, "Error");
          }
        } catch (e) {
          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          //link.setAttribute('download', "export")
          link.download = "reporte.xlsx";
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          //link.download = code+'.pdf'
          link.click();
        }
      });
    },
    copy(orden) {
      this.errors.clear();
      this.loading = true;

      this.mainItem.shipper = orden.shipper;
      this.mainItem.consignee = orden.consignee;            
      this.referencia_cliente = orden.referencia_cliente;      
      this.mainItem.crt = orden.crt;
      this.mainItem.mic = orden.mic;            
      this.mainItem.comentarios = orden.comentarios;
                  
      //Get Next Operaciones
      this.service.getNextOperacion().then((response) => {
        this.mainItem.operacion = response.result;
        this.dialog = true;
      })
      .catch((error) => {
        this.$toast.error(error, "Error");
      })
      .finally(() => {
        this.loading = false;
      });
    },
    print(orden, type) {
      this.dialog_print = true;
      this.service.print(orden.id, type).then((response) => {
        try {
          //Transformo a string la respuesta para poder enviar mensajes de error.
          let result = JSON.parse(this.arrayBufferToString(response));
          if (!result.status) {
            this.$toast.error(result.msg, "Error");
          }
          this.dialog_print = false;
        } catch (e) {
          this.dialog_print = false;
          let blob = new Blob([response], {
            type: "application/pdf",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = orden.guia_madre + ".pdf";
          link.target = "_blank";
          link.click();
        }
      });
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
