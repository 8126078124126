<template>
  <div>
    <Title :pages="[{ icon: 'truck', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <ModalFactura
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog_facturas"
        :parent="parent"
        :service="service"
        @updateDialog="closeModal"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
        :validator="$validator"        
      />

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">                         
                <div class="col-md-3 pt-0">
                  <v-text-field
                    v-model="operacion_selected"
                    autocomplete="off"
                    data-vv-as="operacion"
                    name="operacion"
                    label="Operation Nº"
                    class="pt-0 mt-0"
                  ></v-text-field>
                </div>   
                <div class="col-md-3 pt-0">
                  <v-text-field v-model="house_bl_selected" autocomplete="off" data-vv-as="house_bl" name="house_bl" label="House BL" class="pt-0 mt-0"></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field v-model="master_bl_selected" autocomplete="off" data-vv-as="master_bl" name="master_bl" label="Master BL" class="pt-0 mt-0"></v-text-field>
                </div>                
                             


                <div class="col-md-3 pt-0" style="display: flex;justify-content: end;">
                  <!--<v-btn color="error" class="mr-2" @click="export_filter">Exportar</v-btn>-->
                  <v-btn color="info" class="" @click="filter">Search</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Loading..." hide-default-footer disable-pagination>
          <template v-slot:item="props">
            <tr :style="(!props.item.closed)?'':'color: #999'">
              <td>{{ props.item.operacion }}</td>
              <td>                
                  <v-chip v-for="obj in props.item.house_bls" :key="obj.id" small> 
                    <span 
                      class="truncate"
                      :title="obj.house_bl"
                    >
                      {{ obj.house_bl }}
                    </span>                  
                  </v-chip>                
              </td>
              <td>{{ props.item.master_bl }}</td>
              <td class="justify-content-center px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="cargarFactura(props.item, false)">
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>View</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access && !props.item.closed" v-on="on" small color="green" class="mr-2" @click="cargarFactura(props.item, true)">
                      edit
                    </v-icon>
                  </template>
                  <span>Load/Edit Invoices</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/";
import { agenteMaritimaInvoiceService } from "@/libs/ws/agenteMaritimaInvoiceService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import ModalFactura from "./ModalFactura.vue";
import RolMixin from "@/mixins/RolMixin.vue";

export default {
  components: { Title, ModalFactura, DialogDelete },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      parent: this,
      service: agenteMaritimaInvoiceService,
      page_title: "Maritime InvoiceS",
      modal_title: "Maritime Invoice",
      mainItem: {
        id: "",
        
      },
      defaultItem: null,
      headers: [
        { text: "Operation Nº", align: "left", sortable: true, value: "operacion" },
        { text: "House BLs", align: "left", sortable: true },
        { text: "Master BL", align: "left", sortable: true, value: "master_bl" },        
        { text: "Action", value: "", align: "center" },
      ],
      list: [],
      dialog_delete: false,
      search: "",
      loading: false,
      pagination: {
        rowsPerPage: 10,
      },
      operacion_selected: "",
      house_bl_selected: "",
      master_bl_selected: "",
      dialog_facturas: false,
    };
  },
  mounted() {
    this.defaultItem = Object.assign({}, this.mainItem);
    this.filter();
  },
  methods: {
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
    getFilterParams() {
      const param = {        
        house_bl: this.house_bl_selected,        
        master_bl: this.master_bl_selected,        
        operacion: this.operacion_selected,        
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    cargarFactura(item, update = true){
      if(update){
        this.editedIndex = this.list.indexOf(item);
        this.viewdIndex = -1;
      }else{
        this.viewdIndex = this.list.indexOf(item);
        this.editedIndex = -1;
      }
            
      this.mainItem = JSON.parse(JSON.stringify(item));
      this.dialog_facturas = true;
    },
    closeModal() {
      this.dialog_facturas = false;
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));      
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
