import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home.vue";
import Login from "@/views/Login/Login.vue";
import Usuarios from "@/views/Usuarios/Usuarios.vue";
import Roles from "@/views/Roles/Roles.vue";
import Agents from "@/views/Agents/Agents.vue";

import Shippers from "@/views/Shipper/Shippers.vue";
import Consignees from "@/views/Consignee/Consignees.vue";
import InssuingCarriers from "@/views/InssuingCarrier/InssuingCarriers.vue";
import Airports from "@/views/Airport/Airports.vue";
import Carriers from "@/views/Carrier/Carriers.vue";
import Currencies from "@/views/Currency/Currencies.vue";
import OrdersAereas from "@/views/OrdersAereas/OrdersAereas.vue";

import Notifications from "@/views/Notifications/Notifications.vue";

import TipoContenedor from "@/views/TipoContenedor/TipoContenedor.vue";
import Puerto from "@/views/Puerto/Puerto.vue";
import Buque from "@/views/Buque/Buque.vue";
import CompaniaTrasnporte from "@/views/CompaniaTrasnporte/CompaniaTrasnporte.vue";
import TipoCamion from "@/views/TipoCamion/TipoCamion.vue";
import Lugar from "@/views/Lugar/Lugar.vue";
import Estado from "@/views/Estado/Estado.vue";
import CodigoPuerto from "@/views/CodigoPuerto/CodigoPuerto.vue";
import Naviera from "@/views/Naviera/Naviera.vue";
import TerminalDepFiscal from "@/views/TerminalDepFiscal/TerminalDepFiscal.vue";

import OrdersMaritimasComercial from "@/views/OrdersMaritimas/OrdersMaritimasComercial.vue";
import OrdersMaritimasOperaciones from "@/views/OrdersMaritimas/OrdersMaritimasOperaciones.vue";
import ReportsMaritimas from "@/views/OrdersMaritimas/ReportsMaritimas.vue";

import OrdersTerrestresComercial from "@/views/OrdersTerrestres/OrdersTerrestresComercial.vue";
import OrdersTerrestresOperaciones from "@/views/OrdersTerrestres/OrdersTerrestresOperaciones.vue";

import AgentMaritimaInvoice from "@/views/AgentMaritimaInvoice/AgentMaritimaInvoice.vue";

import ConstantSection from "@/constants/";
import { cryptoUtils } from "@/libs/utils/cryptoUtils";
import { authService } from "@/libs/ws/authService";

Vue.use(VueRouter);

//const external = cryptoUtils.decrypt(localStorage.getItem("external"), Constant.SECRET_PHRASE);

const routes = [
  { path: "/", redirect: "/" + ConstantSection.SECTION_ORDERS },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: "/users",
    name: "Usuarios",
    component: Usuarios,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/roles",
    name: "Roles",
    component: Roles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_ORDERS,
    name: "OrdersAereas",
    component: OrdersAereas,
    meta: {
      requiresAuth: true,
    },
  },  
  {
    path: "/"+ConstantSection.SECTION_NOTIFICATIONS,
    name: "Notifications",
    component: Notifications,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_MARITIME_AGENT_INVOICES,
    name: "Facturas Maritimas",
    component: AgentMaritimaInvoice,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/orders_maritimas_comercial",
    name: "Ordenes Maritimas Comercial",
    component: OrdersMaritimasComercial,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders_maritimas_operaciones",
    name: "Ordenes Maritimas Operaciones",
    component: OrdersMaritimasOperaciones,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders_maritimas_reports",
    name: "Ordenes Maritimas Reportes",
    component: ReportsMaritimas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders_terrestres_comercial",
    name: "Ordenes Terrestres Comercial",
    component: OrdersTerrestresComercial,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders_terrestres_operaciones",
    name: "Ordenes Terrestres Operaciones",
    component: OrdersTerrestresOperaciones,
    meta: {
      requiresAuth: true,
    },
  },


  {
    path: "/"+ConstantSection.SECTION_SHIPPER,
    name: "Shippers",
    component: Shippers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_CURRENCY,
    name: "Currencies",
    component: Currencies,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_CONSIGNEE,
    name: "Consignees",
    component: Consignees,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_INSSURING_CARRIER,
    name: "Inssuing Carriers",
    component: InssuingCarriers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_AIRPORT,
    name: "Airports",
    component: Airports,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_CARRIER,
    name: "Carriers",
    component: Carriers,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/"+ConstantSection.SECTION_ESTADO,
    name: "Estados de Ordenes",
    component: Estado,
    meta: {
      requiresAuth: true,
    },
  },

  //maritimas
  {
    path: "/"+ConstantSection.SECTION_TIPO_CONTENEDOR,
    name: "Tipo de Contenedores",
    component: TipoContenedor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_PUERTO,
    name: "Puertos",
    component: Puerto,
    meta: {
      requiresAuth: true,
    },
  },  
  {
    path: "/"+ConstantSection.SECTION_BUQUE,
    name: "Buques",
    component: Buque,
    meta: {
      requiresAuth: true,
    },
  },
 

  //TERRESTRES
  {
    path: "/"+ConstantSection.SECTION_COMPANIA_TRASNPORTE,
    name: "Companias de Transporte",
    component: CompaniaTrasnporte,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_TIPO_CAMION,
    name: "Tipo de Camiones",
    component: TipoCamion,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_LUGAR,
    name: "Lugares de Salida-Llegada",
    component: Lugar,
    meta: {
      requiresAuth: true,
    },
  },
  
  
  {
    path: "/"+ConstantSection.SECTION_CODIGO_PUERTO,
    name: "Codigos de Puertos",
    component: CodigoPuerto,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_NAVIERA,
    name: "Navieras",
    component: Naviera,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_TERMINAL_DEP_FISCAP,
    name: "Terminales Dep. Fiscales",
    component: TerminalDepFiscal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/"+ConstantSection.SECTION_AGENTE,
    name: "Agentes",
    component: Agents,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authService.isAuthenticated()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    if (authService.isAuthenticated() && to.path == "/login") {
      const redirect = localStorage.getItem("redirect");
      next({
        path: redirect,
      });
    } else {
      next(); // make sure to always call next()!
    }
  }
});

export default router;
